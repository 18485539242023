import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'

const PageTemplate = (props) => {
  return (
    <>
      <Seo slug="erkunden" title="Alle Kategorien - Moabiter Insel" description="Alle Kategorien der Moabiter Insel erkunden" keywords="" noFollow={false} noIndex={false} seoTitle="Alle Kategorien - Moabiter Insel" url="https://www.moabiterinsel.de/erkunden/" />
    </>
  )
}

export const query = graphql`
    query AllParticipants{
      allWpTaxonomy(filter: {name: {eq: "moabit_participant_category"}}) {
        nodes {
          name
          archivePath
        }
      }
      allWpParticipant(sort: {order: ASC, fields: mi_participant___miParticipantShorttitle}) {
        nodes {
          databaseId
          id
          slug
          title
          uri
          participantCategories {
            nodes {
              databaseId
              name
            }
          }
          mi_participant {
            miParticipantShorttitle
            miParticipantHeroSlider {
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 540, maxWidth: 960, quality: 80, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            miParticipantGeodata {
              latitude
              longitude
              streetName
              streetNumber
              placeId
              city
              postCode
              streetAddress
            }
          }
        }
      }
    }
`

export default PageTemplate
